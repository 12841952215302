import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import rocketImage from './Rocket.png';

const hoverAnimation = keyframes`
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-10px); }
`;

const launchAnimation = keyframes`
  0% { transform: translate(0, 0) scale(1); opacity: 1; }
  100% { transform: translate(800px, -800px) scale(0.3); opacity: 0; }
`;

const RocketContainer = styled.div`
  height: 150px;
  width: 150px;
  position: absolute;
  left: ${props => props.left || '10%'};
  top: ${props => props.top || '35%'};
  animation: ${props => props.isLaunching ? launchAnimation : hoverAnimation} ${props => props.isLaunching ? '5s' : '3s'} ease-in-out ${props => props.isLaunching ? 'forwards' : 'infinite'};
`;

const RocketImage = styled.img`
  height: 100%;
  width: 100%;
`;

const SpeechBubbleContainer = styled.div`
  position: absolute;
  left: ${props => props.left || 'calc(10% + 75px)'};
  top: ${props => props.top || 'calc(35% - 1cm - 15px)'};
  z-index: 10;
`;

const SpeechBubble = styled.div`
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
  color: #000;
  font-size: 14px;
  padding: 10px;
  width: 200px;
  opacity: ${props => props.visible ? 1 : 0};
  transform: translateY(${props => props.visible ? 0 : '20px'});
  transition: opacity 0.5s ease, transform 0.5s ease;

  &:after {
    content: "";
    position: absolute;
    bottom: -15px;
    left: 20px;
    border-width: 20px 20px 0;
    border-style: solid;
    border-color: #fff transparent transparent;
  }
`;

export const Rocket = ({ launch, left, top }) => {
  const [isLaunching, setIsLaunching] = useState(false);

  useEffect(() => {
    if (launch) {
      setIsLaunching(true);
    }
  }, [launch]);

  return (
    <RocketContainer isLaunching={isLaunching} left={left} top={top}>
      <RocketImage src={rocketImage} alt="Rocket" />
    </RocketContainer>
  );
};

export const SpeechBubbleComponent = ({ visible, message, left, top }) => {
  return (
    <SpeechBubbleContainer left={left} top={top}>
      <SpeechBubble visible={visible}>
        {message}
      </SpeechBubble>
    </SpeechBubbleContainer>
  );
};