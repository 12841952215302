import React from 'react';
import styled from 'styled-components';

const Card = styled.div`
  background-color: rgba(42, 42, 60, 0.8);
  border-radius: 10px;
  padding: 1.5rem;
  transition: transform 0.3s ease;

  &:hover {
    transform: translateY(-5px);
  }
`;

const Title = styled.h3`
  color: #fff;
  font-size: 1.5rem;
  margin-bottom: 1rem;
`;

const Description = styled.p`
  color: #ccc;
  margin-bottom: 1rem;
`;

const LearnMoreLink = styled.a`
  background-color: #ff5722;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  text-decoration: none;
  display: inline-block;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #ff7043;
  }
`;

const ProjectCard = ({ title, description, link }) => {
  return (
    <Card>
      <Title>{title}</Title>
      <Description>{description}</Description>
      <LearnMoreLink href={link}>Learn More</LearnMoreLink>
    </Card>
  );
};

export default ProjectCard;