import React from 'react';
import styled from 'styled-components';
import backgroundImage2 from './backgroundimg1.png';
import ProjectCard from './ProjectCard';

const SectionContainer = styled.div`
  background-image: url(${backgroundImage2});
  background-size: cover;
  background-position: center;
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4rem 2rem;
`;

const ContentWrapper = styled.div`
  background-color: rgba(42, 42, 60, 0.8);
  border-radius: 10px;
  padding: 2rem;
  max-width: 1200px;
  width: 100%;
`;

const Title = styled.h2`
  color: #fff;
  font-size: 1.5rem;
  margin-bottom: 2rem;
  text-align: center;
`;

const ProjectGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
`;

const projectsData = [
  {
    title: "Website: Zangeres - Nancy Beerens", 
    description: "Ik bouw een website voor mijn zusje Nancy met * De bezoekers informeren met de juiste info * Een intelligent boekinssysteem als backend", 
    link: "https://nancybeerens.com"
  },
  // Add more projects here
  {
    title: "Portfolio Website",
    description: "Dat is deze website uiteraard!",
  }

];

export const ProjectsSection = () => {
    return (
      <SectionContainer id="projects-section">
        <ContentWrapper>
          <Title>My Projects</Title>
          <ProjectGrid>
            {projectsData.map((project, index) => (
              <ProjectCard
                key={index}
                title={project.title}
                description={project.description}
                link={project.link} // This will be undefined if no link is provided
              />
            ))}
          </ProjectGrid>
        </ContentWrapper>
      </SectionContainer>
    );
  };