import styled from 'styled-components';

export const MenuSectionStyle = styled.header`
  background-color: #2d2d2d;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  padding: 15px;
  position: sticky;
  top: 0;
  z-index: 1000;

  ul {
    display: flex;
    gap: 20px;
    justify-content: center;
  }

  li a {
    color: #fff;
    font-size: 18px;
    transition: all 0.3s ease;
    padding: 10px 20px;
    border-radius: 30px;
    cursor: pointer;

    &:hover {
      color: #ff5722;
      background-color: rgba(255, 87, 34, 0.1);
    }
  }
`;

export const ButtonStyle = styled.button`
  background-color: #ff5722;
  border-radius: 30px;
  color: white;
  cursor: pointer;
  border: none;
  margin-top: -30px;
  padding: 10px 20px;
  font-size: 18px;
  transition: all 0.3s ease;

  &:hover {
    background-color: #ff7043;
    transform: translateY(-2px);
  }
`;

export const HeroContainer = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  overflow: hidden;
  background-image: url(${props => props.backgroundImage});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`;

export const ContentContainer = styled.div`
  text-align: center;
  z-index: 10;
  padding-bottom: 50px;
`;

export const SectionContainer = styled.div`
  background-image: url(${props => props.backgroundImage});
  background-size: cover;
  background-position: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
`;

export const ContentWrapper = styled.div`
  background-color: rgba(42, 42, 60, 0.8);
  border-radius: 10px;
  padding: 2rem;
  max-width: 800px;
  width: 100%;
`;

export const Title = styled.h2`
  color: #fff;
  font-size: 2.5rem;
  margin-bottom: 1.5rem;
  text-align: center;
`;

export const TextArea = styled.textarea`
  width: 100%;
  min-height: 150px;
  padding: 0.5rem;
  margin-bottom: 1rem;
  border-radius: 5px;
  border: none;
  font-size: 1rem;
`;

export const SubmitButton = styled.button`
  background-color: #ff5722;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #ff7043;
  }
`;