import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Link } from 'react-scroll';
import styled from 'styled-components';
import { 
  MenuSectionStyle, 
  HeroContainer
} from './StyledComponents';
import { Rocket, SpeechBubbleComponent } from './RocketComponents';
import { About, Contact } from './AboutContact';
import { ProjectsSection } from './ProjectsSection';
import backgroundImage1 from './backgroundimg.png';

const ContentContainer = styled.div`
  position: absolute;
  bottom: 20%;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  z-index: 10;
`;

const ButtonStyle = styled.button`
  background-color: #ff5722;
  border-radius: 30px;
  color: white;
  cursor: pointer;
  border: none;
  padding: 15px 30px;
  font-size: 20px;
  transition: all 0.3s ease;

  &:hover {
    background-color: #ff7043;
    transform: translateY(-2px);
  }
`;

const MenuSection = () => {
  return (
    <MenuSectionStyle>
      <nav>
        <ul>
          <li><Link to="hero-section" smooth={true} duration={500}>Home</Link></li>
          <li><Link to="about-section" smooth={true} duration={500}>About</Link></li>
          <li><Link to="projects-section" smooth={true} duration={500}>Projects</Link></li>
          <li><Link to="contact-section" smooth={true} duration={500}>Contact</Link></li>
        </ul>
      </nav>
    </MenuSectionStyle>
  );
};

const HeroSection = () => {
  const [launchRocket, setLaunchRocket] = useState(false);
  const [showSpeechBubble, setShowSpeechBubble] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowSpeechBubble(false);
    }, 5000);

    return () => clearTimeout(timer);
  }, []);

  const handleGetStartedClick = () => {
    setLaunchRocket(true);
    setShowSpeechBubble(false);
    setTimeout(() => {
      const AboutSection = document.getElementById('about-section');
      if (AboutSection) {
        AboutSection.scrollIntoView({ behavior: 'smooth' });
      }
    }, 2000);
  };

  return (
    <HeroContainer id="hero-section" backgroundImage={backgroundImage1}>
      <Rocket launch={launchRocket} left="15%" top="40%" />
      <SpeechBubbleComponent 
        visible={showSpeechBubble} 
        message="Click 'Get Started' to launch the rocket and explore my projects!"
        left="calc(15% + 75px)"
        top="calc(40% - 100px)"
      />
      <ContentContainer>
        <ButtonStyle onClick={handleGetStartedClick}>
          Get Started
        </ButtonStyle>
      </ContentContainer>
    </HeroContainer>
  );
};

const App = () => {
  return (
    <Router>
      <MenuSection />
      <HeroSection />
      <About />
      <ProjectsSection />
      <Contact />
    </Router>
  );
};

export default App;