import React from 'react';
import styled from 'styled-components';
import backgroundImage2 from './backgroundimg1.png';

const SectionContainer = styled.div`
  background-image: url(${backgroundImage2});
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
`;

const ContentWrapper = styled.div`
  background-color: rgba(42, 42, 60, 0.8);
  border-radius: 10px;
  padding: 2rem;
  max-width: 800px;
  width: 100%;
`;

const Title = styled.h2`
  color: #fff;
  font-size: 1.5rem;
  margin-bottom: 1.5rem;
  text-align: center;
`;

const Paragraph = styled.p`
  color: #fff;
  margin-bottom: 1rem;
  white-space: pre-line;
`;

const TextArea = styled.textarea`
  width: 100%;
  min-height: 150px;
  padding: 0.5rem;
  margin-bottom: 1rem;
  border-radius: 5px;
  border: none;
  font-size: 1rem;
`;

const SubmitButton = styled.button`
  background-color: #ff5722;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #ff7043;
  }
`;

export const About = () => (
  <SectionContainer id="about-section">
    <ContentWrapper>
      <Title>Even voorstellen</Title>
      <Paragraph>
        {`De afgelopen periode ben ik steeds meer geïnteresseerd geraakt in (Full-stack) Webdevelopment. De vele mogelijkheden, de creatieve uitdagingen en de groeiende populariteit binnen dit vakgebied hebben mij enorm aangetrokken. Wat begon als een eigen webserver op te zetten bloeide op naar een steeds meer groeiende passie. Ik ben begonnen met een zelfstudie om hier in door te gaan en natuurlijk een gave baan in te vinden.
Met behulp van dit portfolio hoop ik mijn kennis te onderbouwen. Mede dankzij mijn achtergrond als systeembeheerder heb ik een unieke skillset ontwikkeld zo weet ik bijvoorbeeld wat er voor nodig is om een website te hosten, welke systeemvereiste er nodig zijn en het monitoren van processen om na te gaan wat de huidige load is`}
      </Paragraph>
    </ContentWrapper>
  </SectionContainer>
);

export const Contact = () => (
  <SectionContainer id="contact-section">
    <ContentWrapper>
      <Title>Neem Contact met mij op</Title>
      <form>
        
        <TextArea placeholder="Bericht..." />
        <SubmitButton type="submit">Send Message</SubmitButton>
      </form>
    </ContentWrapper>
  </SectionContainer>
);